<template>
	<div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
		<OnboardingHeader progress="width: 25%" :btnText="$t('onboarding.header')" />
		<div class="px-6 pt-4 pb-3.5">
			<div class="h-40 rounded-full py-10 bg-gradient-to-b from-halfColonialWhite-transparent30 to-halfColonialWhite-transparent">
				<PinCode v-if="pinExist" class="text-center h-20" id="pinCode" />
				<div v-else class="text-center h-20" id="pinCode">
					<p class="font-heading text-subS text-redDamask font-extrabold">{{ $t('login.pin') }}</p>
					<span class="display-block font-heading text-h4 text-sepiaBlack dark:text-white">0000</span>
				</div>
			</div>
			<p class="font-body text-bodyS text-redDamask tracking-midWide font-medium">{{ $t('onboarding.2') }}</p>
			<h4 class="font-heading text-h4 dark:text-white text-sepiaBlack">{{ $t('login.pin') }}</h4>
			<p class="font-body text-bodyL dark:text-white text-sepiaBlack leading-normal">{{ $t('onboarding.2text') }}</p>
		</div>
		<OnboardingFooter prev="Onboarding1" next="Onboarding3" />
	</div>
</template>

<script>

import OnboardingHeader from '@/components/mobile/OnboardingHeader.vue';
import OnboardingFooter from '@/components/mobile/OnboardingFooter.vue';
import PinCode from "@/components/PinCode.vue";

export default {
	name: 'Onboard1',
	components: {
		OnboardingHeader,
		OnboardingFooter,
		PinCode
	},
	mounted() {
		this.$i18n.locale = this.lang;
		this.pinCheck();
	},
	data() {
		return {
			lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
			pinExist: false,
		}
	},
	methods: {
		pinCheck() {
		try{
			const result = this.$store.dispatch('pinCheck');
			result.then(res => {
				if (res && res != '') {
					this.pinExist = true;
				} else {
					this.pinExist = false;
				}
			}).catch(() => {
				this.pinExist = false;
			})
		}catch(e){
			this.pinExist = false;
		}
		},
	},
}
</script>

<style>
#pinCode > .text-h4 {
	font-size: 3rem;
	line-height: 130%;
}
</style>