<template>
  <div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
    <OnboardingHeader progress="width: 100%" :btnText="$t('onboarding.endHeader')" />
    <div class="px-6 pt-4 pb-3.5 text-center">
      <div class="mb-8 mt-16">
        <h4 class="font-heading text-h4 dark:text-white text-sepiaBlack">{{ $t('onboarding.endMessage') }}</h4>
        <p class="font-body text-bodyL text-redDamask tracking-midWide">{{ $t('onboarding.endTitle') }}</p>
      </div>
      <p class="font-body text-bodyL dark:text-white text-sepiaBlack leading-normal">{{ $t('onboarding.endText') }}</p>
    </div>
    <OnboardingFooter end />
  </div>
</template>

<script>

import OnboardingHeader from '@/components/mobile/OnboardingHeader.vue';
import OnboardingFooter from '@/components/mobile/OnboardingFooter.vue';

export default {
  name: 'Onboard1',
  components: {
    OnboardingHeader,
    OnboardingFooter
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  }
}
</script>