<template>
    <div class="h-screen w-tv-main-axis mx-auto text-center relative" v-on:click="click" >
        <Header />
        <div class="flex flex-col justify-center align-center h-4/5">
            <div class="hidden mx-auto" id="errorM">
                <SystemMessage :text="$t('timeline.errorMessage')" type='error' />
            </div>
            <div class="hidden mx-auto" id="successM">
                <h1 class="font-heading text-h2 text-redDamask">{{ $t('timeline.successTitle', {input: response?.user_name }) }}</h1>
                <SystemMessage :text="$t('timeline.successMessage')" type='success' class="mt-2"/>
            </div>
            <div class="hidden mx-auto" id="warningM">
                <SystemMessage :text="$t('timeline.warningMessage')" type='warning' />
            </div>
            <div class="hidden mx-auto" id="warningFinished">
                <SystemMessage :text="$t('timeline.warningFinished')" type='warning' />
            </div>
            <h1 class="font-heading text-h1 text-redDamask pt-24">{{ $t('timeline.timelineTitle') }}</h1>
            <p class="font-body text-bodyXL text-sepiaBlack dark:text-halfColonialWhite mb-4">{{ $t('timeline.timelineSubtitle') }}</p>
            <PinCodeInput @pinChange="pinChanged" @focusChange="focusChange" :inputValueProp1="inputValue1" :inputValueProp2="inputValue2" :inputValueProp3="inputValue3" :inputValueProp4="inputValue4"/>
            <div class="flex justify-center gap-2 mt-10">
                <Button buttonType='secondary' :text="$t('timeline.backBtn')" :disabledBtn="btnDisabel" @click="this.$router.go(-1)"/>
                <Button buttonType='primary' :text="$t('timeline.saveBtn')" :disabledBtn="btnDisabel" v-on:click="saveToTimeline()"/>
            </div>
        </div>
        <div v-if="device == 'tv'" class="grid grid-cols-3 gap-2 mt-10 mb-4 w-52 mx-auto">
            <Button buttonType='secondary' text="1" :disabledBtn="btnDisabel" v-on:click="input(1)" />
            <Button buttonType='secondary' text="2" :disabledBtn="btnDisabel" v-on:click="input(2)" />
            <Button buttonType='secondary' text="3" :disabledBtn="btnDisabel" v-on:click="input(3)" />
            <Button buttonType='secondary' text="4" :disabledBtn="btnDisabel" v-on:click="input(4)" />
            <Button buttonType='secondary' text="5" :disabledBtn="btnDisabel" v-on:click="input(5)" />
            <Button buttonType='secondary' text="6" :disabledBtn="btnDisabel" v-on:click="input(6)" />
            <Button buttonType='secondary' text="7" :disabledBtn="btnDisabel" v-on:click="input(7)" />
            <Button buttonType='secondary' text="8" :disabledBtn="btnDisabel" v-on:click="input(8)" />
            <Button buttonType='secondary' text="9" :disabledBtn="btnDisabel" v-on:click="input(9)" />
            <Button buttonType='secondary' class="col-start-2" text="0" :disabledBtn="btnDisabel" v-on:click="input(0)" />
            <Button buttonType='secondary' class="col-start-3" text="<-" :disabledBtn="btnDisabel" v-on:click="input('')" />

        </div>
    </div>
</template>

<script>

import Header from "@/components/Header.vue";
import Button from '@/components/Button.vue';
import PinCodeInput from "@/components/tablet/PinCodeInput.vue";
import SystemMessage from '@/components/SystemMessage.vue';

export default {
    name: "Tablet",
    components: {
        Header,
        Button,
        PinCodeInput,
        SystemMessage
    },
    data(){
        return {
            baseURL: process.env.VUE_APP_AXIOS_URL,
            id: this.$route.params.id,
            pinInput: '',
            response: {},
            seconds: 60,
			standard_sec: 60,
            device: this.$route.name == 'TVPin' ? 'tv' : 'tablet',
            btnDisabel: false,
            inputValue1: '',
            inputValue2: '',
            inputValue3: '',
            inputValue4: '',
            focus: 1,
            lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
        }
    },
    mounted() {
        const authUser = localStorage.getItem('token') ? JSON.parse(window.atob(localStorage.getItem('token').split('.')[1])) : null;
  		if (!authUser) {
        	this.startTimer();
		}
        document.querySelector('#custom_input_' + this.focus).focus();
        this.$i18n.locale = this.lang;
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        redirect() {
            this.$router.push('/' + this.device + 's?category=' +localStorage.getItem(this.device + '_category') + '&seconds=60');
        },
        saveToTimeline() {
            document.querySelector("#systemM").classList.remove("hidden");
            this.btnDisabel = true;
            const search_input = this.pinInput;
            this.axios.post(this.baseURL + `/wp-json/wp/v2/timelinebypin`, {entity_id: this.id, pin: search_input, language: 'en'}, {})
            .then(response => {
                const response_data = response.data;
                this.response = response.data;
                if (response_data.error) {
                    document.querySelector("#errorM").classList.remove("hidden");
                    this.btnDisabel = false;
                } else {
                    if (this.response.text == 'Enity successfully added to timeline!') {
                        document.querySelector("#successM").classList.remove("hidden");
                        document.querySelector("#errorM").classList.add("hidden");
                        document.querySelector("#warningFinished").classList.add("hidden");
                        setTimeout(this.redirect, 3000);
                        this.btnDisabel = false;
                    }
                    else {
                        if (this.response.text == 'Enity already added to timeline!') {
                            document.querySelector("#errorM").classList.add("hidden");
                            document.querySelector("#warningM").classList.remove("hidden");
                            document.querySelector("#warningFinished").classList.add("hidden");
                            setTimeout(this.redirect, 3000);
                            this.btnDisabel = false;
                        } else {
                            document.querySelector("#errorM").classList.add("hidden");
                            document.querySelector("#warningM").classList.add("hidden");
                            document.querySelector("#warningFinished").classList.remove("hidden");
                            setTimeout(this.redirect, 5000);
                            this.btnDisabel = false;
                        }
                    }
                }
            }).catch( () => {
                this.btnDisabel = false;
                document.querySelector("#errorM").classList.remove("hidden");
            });
        },
        pinChanged(pin, index, value) {
            if (index < 4 && value != '') {
                this.focus = index + 1;
            }
            this.pinInput = pin;
            switch(index) {
                case 1:
                    this.inputValue1 = value;
                    break;
                case 2:
                    this.inputValue2 = value;
                    break;
                case 3:
                    this.inputValue3 = value;
                    break;
                case 4:
                    this.inputValue4 = value;
                    break;
            }
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        startTimer() {
            this.seconds = this.standard_sec;
            setTimeout(this.countdown, 1000);
        },
        countdown() {
            if (this.seconds > 0) {
                this.seconds -= 1;
                setTimeout(this.countdown, 1000);
            } else {
				this.$router.push('/' + this.device + 's?category=' +localStorage.getItem(this.device + '_category') + '&seconds=0');
			}
        },
        click() {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        handleScroll (event) {
            if (this.seconds > 0) {
                this.seconds = this.standard_sec;
            }
        },
        input(value) {
            switch(this.focus) {
                case 1:
                    this.inputValue1 = value;
                    break;
                case 2:
                    this.inputValue2 = value;
                    break;
                case 3:
                    this.inputValue3 = value;
                    break;
                case 4:
                    this.inputValue4 = value;
                    break;
                default:
                    this.inputValue1 = value;
                    this.focus = 1;
                    break;
            }
            this.pinInput = `${this.inputValue1}${this.inputValue2}${this.inputValue3}${this.inputValue4}`;
            if (this.focus < 4 && value != '') {
                this.focus += 1;
            }
            document.querySelector('#custom_input_' + this.focus).focus();

        },
        focusChange(value) {
            this.focus = value;
        }
    },
};
</script>
