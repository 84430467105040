<template>
<div class="p-6 absolute w-full bottom-0 left-0">
  <div v-if="end">
    <div class="text-center">
      <router-link :to="{ name: 'MobileMenu' }">
        <Button :text="$t('onboarding.startBtn')" buttonType="primary" fullWidth />
      </router-link>
    </div>
  </div>
  <div v-else>
    <div class="flex justify-between items-center">
      <router-link :to="{ name: prev }">
        <BaseIcon name="arrowLeftWhite" bg= "bg-redDamask bg-opacity-20" />
      </router-link>
      <router-link :to="{ name: next }">
        <Button :text="$t('onboarding.footerBtn')" buttonType="secondary" />
      </router-link>
    </div>
  </div>
</div>
</template>

<script>

import Button from '@/components/Button.vue';
import BaseIcon from '@/components/BaseIcon.vue';

export default {
  name: 'OnboardingFooter',
  components: {
    Button,
    BaseIcon
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },
  props: {
    prev: {
      type: String,
      default: '#'
    },
    next: {
      type: String,
      default: '#'
    },
    end: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>