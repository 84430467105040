<template>
  <div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
    <OnboardingHeader progress="width: 75%" :btnText="$t('onboarding.header')" />
    <div class="px-6 pt-4 pb-3.5">
      <div class="h-40 rounded-lg bg-gradient-to-b from-halfColonialWhite-transparent30 to-halfColonialWhite-transparent text-center">
        <img class="rounded w-40 mx-auto" :src="timeline()" >
      </div>
      <p class="font-body text-bodyS text-redDamask tracking-midWide font-medium">{{ $t('onboarding.4') }}</p>
      <h4 class="font-heading text-h4 dark:text-white text-sepiaBlack">{{ $t('onboarding.4title') }}
        <img src="@/assets/camera.png" alt="camera" class="inline h-7 pb-2.5">
      </h4>
      <p class="font-body text-bodyL dark:text-white text-sepiaBlack leading-normal">{{ $t('onboarding.4text') }}</p>
    </div>
    <OnboardingFooter prev="Onboarding3" next="OnboardingEnd" />
  </div>
</template>

<script>

import OnboardingHeader from '@/components/mobile/OnboardingHeader.vue';
import OnboardingFooter from '@/components/mobile/OnboardingFooter.vue';

export default {
  name: 'Onboard1',
  components: {
    OnboardingHeader,
    OnboardingFooter
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },
  methods: {
        timeline(){
            return require("../../assets/slider/slide5.png");
        },
    },
}
</script>