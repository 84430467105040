<template>
<div class="mx-6 mt-4 mb-5">
  <div class="flex justify-between">
    <router-link :to="{ name: href }">
      <Button :text="btnText" buttonType="secondary" />
    </router-link>
    <img v-bind:src="getLogo()" alt="Logo" class="w-12">
  </div>
  <div class="flex items-center pt-2">
    <div class="relative flex-grow pr-6">
      <div class="overflow-hidden h-px text-xs flex rounded dark:bg-white bg-mercury-lightest">
        <div :style= progress class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-redDamask"></div>
      </div>
    </div>
    <div class="font-body text-bodyL text-redDamask flex-none w-10 tracking-wider text-right">{{getProgress(progress)}}</div>
  </div>
</div>
</template>

<script>

import Button from '@/components/Button.vue';

export default {
  name: 'SelfieInstructionHeader',
  components: {
    Button
  },
  props: {
    progress: {
      type: String,
      required: false,
      default: 'width: 0%'
    },
    btnText: {
      type: String,
      required: false
    },
    href: {
      type: String,
      required: false,
      default: 'Selfie'
    }
  },
  methods: {
    getLogo: function() {
      const element = document.getElementById("app");
      if (element.classList.contains('darkMode')) {
        return require("../../assets/logo_dark.png");
      } else {
        return require("../../assets/logo_light.png");
      }
    },
    getProgress: function(x) {
      let array = x.split(' ');
      return array[1];
    }
  }

}
</script>
