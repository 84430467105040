<template>
  <div class="max-w-screen-mobile mx-auto h-screen py-2 relative">
    <SelfieInstructionHeader progress="width: 100%" :btnText="$t('selfieInstruction.endHeader')" :href="href"/>
    <div class="px-6 pt-4 pb-3.5 text-center">
      <div class="mb-8 mt-16">
        <h4 class="font-heading text-h4 dark:text-potPourri text-sepiaBlack">{{ $t('selfieInstruction.endMessage') }}</h4>
        <p class="font-body text-bodyL text-redDamask tracking-midWide">{{ $t('selfieInstruction.endTitle') }}</p>
      </div>
      <p class="font-body text-bodyL dark:text-potPourri text-sepiaBlack leading-normal">{{ $t('selfieInstruction.endText') }}</p>
    </div>
    <SelfieInstructionFooter end />
  </div>
</template>

<script>

import SelfieInstructionHeader from '@/components/mobile/SelfieInstructionHeader.vue';
import SelfieInstructionFooter from '@/components/mobile/SelfieInstructionFooter.vue';

export default {
  name: 'SelfieInstructionEnd',
  components: {
    SelfieInstructionHeader,
    SelfieInstructionFooter
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
      href: 'MobileMenu',
    }
  }
}
</script>