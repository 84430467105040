<template>
  <div class="max-w-screen-mobile mx-auto h-screen  py-2 relative">
    <SelfieInstructionHeader progress="width: 25%" :btnText="$t('selfieInstruction.header')" />
    <div class="px-6 pt-4 pb-3.5">
      <div class="rounded-lg mb-4 bg-gradient-to-b from-halfColonialWhite-transparent30 to-halfColonialWhite-transparent text-center">
        <img class="rounded w-full mx-auto" :src="image()" >
      </div>
      <p class="font-body text-bodyS text-redDamask tracking-midWide font-medium">{{ $t('selfieInstruction.1') }}</p>
      <h4 class="font-heading text-h4 dark:text-potPourri text-sepiaBlack my-4">{{ $t('selfieInstruction.1title') }}</h4>
      <p class="font-body text-bodyL dark:text-potPourri text-sepiaBlack leading-normal">{{ $t('selfieInstruction.1text') }}</p>
    </div>
    <SelfieInstructionFooter next="SelfieInstruction2" prev="MobileMenu"/>
  </div>
</template>

<script>

import SelfieInstructionHeader from '@/components/mobile/SelfieInstructionHeader.vue';
import SelfieInstructionFooter from '@/components/mobile/SelfieInstructionFooter.vue';

export default {
  name: 'SelfieInstruction1',
  components: {
    SelfieInstructionHeader,
    SelfieInstructionFooter
  },
  mounted() {
    this.$i18n.locale = this.lang;
  },
  data() {
    return {
      lang: localStorage.getItem('language') ? localStorage.getItem('language') : 'ro',
    }
  },
  methods: {
    image(){
      return require("../../../assets/slider/expl-selfie-1.svg");
    },
  },
}
</script>
